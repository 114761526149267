import React, { useEffect } from 'react'
import useStyles from './styles'
import { toast } from 'react-toastify'
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import { Button, Typography } from '../../components/Wrappers/Wrappers'
import { withStyles } from '@material-ui/styles'
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    InputLabel,
    FormLabel,
    FormHelperText,
    Checkbox,
    FormControl,
    TextField,
    Grid,
    Box
} from "@material-ui/core";
import Widget from '../../components/Widget/Widget'
import {
    Delete,
    Description
} from '@material-ui/icons';

const AddDocument = () => {
    const currentDate = new Date();

    const [newDocument, setNewDocument] = React.useState({
        documentName: '',
        executiveDate: getProperCurrentDate(),
        typeID: 0,
        objectID: 0,
        documentFileID: null,
        fltInterval: 0,
        checkingDP: 0,
        checkingUI: 0,
        executorAKODP: 0,
        executorAKOIU: 0,
        yearOfNextDP: currentDate.getFullYear(),
        monthOfNextDP: currentDate.getMonth() + 1,
        yearOfNextIU: currentDate.getFullYear(),
        monthOfNextIU: currentDate.getMonth() + 1,
        executorAKOLiftInspection: 0,
        journalIsNeeded: 0,
        dateOfLastFlt: getProperCurrentDate(),
        dateOfNextFlt: getProperCurrentDate(),
        riskAssessmentDoneDate: getProperCurrentDate(),
        permitExpirationDate: getProperCurrentDateLastOfMonth(),
        liftInspectionInterval: 0,
        previousDateOfLiftInspection: getProperCurrentDate(),
        dateOfNextLiftInspection: getProperCurrentDate(),
        formID: null,
        monitoringInterval: "Ständig övervakning krävs",
        fltIsOkLift: false,
        isOk: false
    });

    const [documentTypes, setDocumentTypes] = React.useState([]);
    const [checkedQuestions, setCheckedQuestions] = React.useState([]);
    const [templateTypeId, setTemplateTypeId] = React.useState(-1);
    const [templateList, setTemplateList] = React.useState([]);
    const [questionList, setQuestionList] = React.useState([]);
    const [canAddDocuments, setCanAddDocuments] = React.useState(false);
    const [canCreateTemplates, setCanCreateTemplate] = React.useState(false);
    const [questionGroupList, setQuestionGroupList] = React.useState([]);
    const [questionGroupId, setQuestionGroupId] = React.useState("0");
    const [buttonClicked, setButtonClicked] = React.useState(false);
    const [dateOfNextJournal, setDateOfNextJournal] = React.useState(currentDate.getFullYear().toString());
    const [isCopy, setIsCopy] = React.useState(false);
    const [constantMonitoringNeeded, setConstantMonitoringNeeded] = React.useState(true);
    const [docName, setDocName] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const [inspectionMonth, setInspectionMonth] = React.useState("1");
    const [inspectionYear, setInspectionYear] = React.useState("1");
    const [docFileName, setDocFileName] = React.useState({});
    const [usingTemplateInstead, setUsingTemplateInstead] = React.useState(false);
    const [isArchive, setIsArchive] = React.useState(false);
    const [objectData, setObjectData] = React.useState([]);
    const [object, setObject] = React.useState([]);
    const fileInput = React.useRef(null);
    const templateID = "0ec78479-d7d9-4fcf-8917-TOM";

    const handleFile = async (event) => {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('File', file);
        formData.append('ShowOnStartPage', true);
        formData.append('ObjectID', newDocument.objectID);

        axios.post('/Documents/upload', formData).then(
            res => {
                if (res.status === 201) {
                    setDocFileName(res.data.fileName);
                    setNewDocument({ ...newDocument, documentFileID: res.data.id });
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        return;
    }

    const GreenCheckbox = withStyles({
        root: {
            color: "#17a506",
            '&$checked': {
                color: "#17a506",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    function removeClick() {
        setDocFileName("");
        setNewDocument({ ...newDocument, documentFileID: null });
    }

    function getProperCurrentDate() {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return finalDateString;
    }

    function getProperCurrentDateLastOfMonth() {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth();

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = 1;

        finalDateString += day;

        return finalDateString;
    }

    function getProperCurrentDateAddMonths(monthsToAdd) {
        let finalDateString = currentDate.getFullYear() + "-";

        let month = currentDate.getMonth() + monthsToAdd;

        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = "" + (month + 1)
        }

        finalDateString += month + "-";

        let day = currentDate.getDate().toString();

        if (day.length === 1) {
            day = "0" + (day);
        }

        finalDateString += day;

        return finalDateString;
    }

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');

        if (currentUser) {
            currentUser = JSON.parse(currentUser);
            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "71") > -1) { //See if user is allowed to create documents
                    setCanAddDocuments(true);
                } else {
                    setCanAddDocuments(false);
                }

                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "81") > -1) { //See if user is allowed to create templates
                    setCanCreateTemplate(true);
                } else {
                    setCanCreateTemplate(false);
                }
            }
        }

        setInspectionMonth(currentDate.getMonth() + 1);
        setInspectionYear(currentDate.getFullYear());

        axios.get("/Objects/data").then(
            res => {
                if (res.status === 200) {
                    setObjectData(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        //axios.get("/FormQuestion/getall").then( //TODO: Remove if no problems found
        //    res => {
        //        if (res.status === 200) {
        //            setQuestionList(res.data);

        //            let checkedQuestionsChange = [];
        //            for (var i = 1; i < res.data.length; i++) {
        //                checkedQuestionsChange.push(false);
        //            }

        //            setCheckedQuestions(checkedQuestionsChange);
        //        }
        //    }
        //).catch(err => {
        //    console.log('err');
        //    console.log(err);
        //});

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const objId = urlParams.get('objektID');
            const archiveParam = urlParams.get('arkiv');
            let newDoc = { ...newDocument };
            newDoc.objectID = parseInt(objId);

            if (archiveParam !== null) {
                setIsArchive(true);
            }

            //setNewDocument({ ...newDocument, objectID: parseInt(objId) });

            axios.get('/objects/' + objId).then(
                res => {
                    if (res.status === 200) {
                        if (res.data.fltInterval > 0) {
                            let date = new Date(getProperCurrentDate());
                            date.setMonth(date.getMonth() + res.data.fltInterval);
                            newDoc.dateOfNextFlt = date.toISOString().split('T')[0];
                        }

                        axios.get("/Documents/documentTypes/" + res.data.type.mainType.id + "/" + objId).then(
                            res => {
                                if (res.status === 200) {
                                    setDocumentTypes(res.data);
                                }
                            }
                        ).catch(err => {
                            console.log('err');
                            console.log(err);
                        })

                        setObject(res.data);
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });

            setNewDocument(newDoc);

            //Choose document type quickly through the address bar
            const docTypeID = urlParams.get('dokumentTyp');
            if (docTypeID) {
                setNewDocument({ ...newDocument, typeID: docTypeID });
            }
        }
    }, []);

    const classes = useStyles()

    function handleChangeLiftInspectionDates(e) {
        let tempDoc = { ...newDocument };
        let period = 1;

        if (object.controlRegulation === 0 || object.controlRegulation === 3) {
            period = 2;
        } else if (object.controlRegulation === 4) {
            period = 0;
        }

        if (e.target.name === "dateOfNextLiftInspection") {
            tempDoc.dateOfNextLiftInspection = e.target.value;
            let date = new Date(e.target.value);
            date.setMonth(date.getMonth() + period);
            tempDoc.permitExpirationDate = date.toISOString().split('T')[0];
        } else if (e.target.name === 'inspectionMonth') {
            setInspectionMonth(e.target.value);
            let date = new Date(tempDoc.previousDateOfLiftInspection);
            date.setFullYear(inspectionYear);
            date.setMonth(e.target.value);
            date.setDate(0);
            tempDoc.permitExpirationDate = date.toLocaleDateString();
            tempDoc.dateOfNextLiftInspection = date.toLocaleDateString();
        } else if (e.target.name === 'inspectionYear') {
            setInspectionYear(e.target.value);
            let date = new Date(tempDoc.previousDateOfLiftInspection);
            date.setFullYear(e.target.value);
            date.setMonth(inspectionMonth);
            date.setDate(0);
            tempDoc.permitExpirationDate = date.toISOString().split('T')[0];
            tempDoc.dateOfNextLiftInspection = date.toLocaleDateString();
        } else if (e.target.name === "permitExpirationDate") {
            tempDoc.permitExpirationDate = e.target.value;

            let date = new Date(e.target.value);
            date.setMonth(date.getMonth());

            setInspectionMonth(date.getMonth() + 1);
            setInspectionYear(date.getFullYear());

            tempDoc.dateOfNextLiftInspection = date.toISOString().split('T')[0];
        } else if (e.target.name === 'previousDateOfLiftInspection') {
            tempDoc.previousDateOfLiftInspection = e.target.value;
            tempDoc.executiveDate = e.target.value;

            if (newDocument.liftInspectionInterval > 0) {
                let date = new Date(e.target.value);
                date.setDate(1);
                date.setFullYear(date.getFullYear() + newDocument.liftInspectionInterval);
                tempDoc.dateOfNextLiftInspection = date.toISOString().split('T')[0];
                date.setMonth(date.getMonth() + period + 1);
                date.setDate(0);
                tempDoc.permitExpirationDate = date.toISOString().split('T')[0];
            } else {
                tempDoc.dateOfNextLiftInspection = "";
                tempDoc.permitExpirationDate = "";
            }
        } else if (e.target.name === 'liftInspectionInterval') {
            tempDoc.liftInspectionInterval = e.target.value;

            if (parseInt(e.target.value) > 0) {
                let date = new Date(newDocument.previousDateOfLiftInspection);
                date.setDate(1);
                date.setFullYear(date.getFullYear() + parseInt(e.target.value));
                tempDoc.dateOfNextLiftInspection = date.toISOString().split('T')[0];
                date.setMonth(date.getMonth() + period + 1);
                date.setDate(0);
                tempDoc.permitExpirationDate = date.toISOString().split('T')[0];
                setInspectionMonth(date.getMonth() + 1);
                setInspectionYear(date.getFullYear());
            } else {
                tempDoc.dateOfNextLiftInspection = "";
                tempDoc.permitExpirationDate = "";
            }
        } else if (e.target.name === 'dateOfLastFlt') {
            tempDoc.dateOfLastFlt = e.target.value;

            if (newDocument.fltInterval !== 0) {
                let date = new Date(e.target.value);
                date.setMonth(date.getMonth() + newDocument.fltInterval)
                tempDoc.dateOfNextFlt = date.toISOString().split('T')[0];
            }
        } else if (e.target.name === 'fltInterval') {
            let date = new Date(newDocument.dateOfLastFlt);
            date.setMonth(date.getMonth() + e.target.value);
            tempDoc.fltInterval = e.target.value;
            tempDoc.dateOfNextFlt = date.toISOString().split('T')[0];
        }

        setNewDocument(tempDoc);
    }

    function handleChange(e) {
        setNewDocument({
            ...newDocument,
            [e.target.name]: e.target.value,
        });
    }

    function handleChangeDPDates(e) {
        let tempDoc = { ...newDocument };
        let period = 0;

        /*
         * 0: none
         * 1: MSB
         * 2: AFS
         */

        if (object.controlRegulation === 2 || object.controlRegulation === 0) {
            period = 5;
        } else if (object.controlRegulation === 1 || object.controlRegulation === 3) {
            period = 2;
        }

        if (e.target.name === "yearOfNextDP") {
            let month = parseInt(newDocument.monthOfNextDP);

            let date = new Date(e.target.value, month + period, 1);
            const finalDate = date.toISOString().split('T')[0];

            tempDoc.yearOfNextDP = e.target.value;
            tempDoc.permitExpirationDate = finalDate;

        } else if (e.target.name === "monthOfNextDP") {
            let date = new Date(newDocument.yearOfNextDP, e.target.value + period, 1);
            const finalDate = date.toISOString().split('T')[0];

            tempDoc.monthOfNextDP = e.target.value;
            tempDoc.permitExpirationDate = finalDate;

        } else if (e.target.name === "permitExpirationDate") {
            let date = new Date(e.target.value);
            tempDoc.permitExpirationDate = e.target.value;
            date.setMonth(date.getMonth() - period);
            tempDoc.monthOfNextDP = date.getMonth() + 1;
            tempDoc.yearOfNextDP = date.getFullYear();
        }

        setNewDocument(tempDoc);
    }

    function handleChangeIUDates(e) {
        let tempDoc = { ...newDocument };
        let period = 0;

        if (object.controlRegulation === 1) {
            period = 0;
        } else if (object.controlRegulation === 2 || object.controlRegulation === 0) {
            period = 5;
        }

        if (e.target.name === "yearOfNextIU") {
            let month = parseInt(newDocument.monthOfNextIU);

            let date = new Date(e.target.value, month + period, 1);
            const finalDate = date.toISOString().split('T')[0];

            tempDoc.yearOfNextIU = e.target.value;
            tempDoc.permitExpirationDate = finalDate;

        } else if (e.target.name === "monthOfNextIU") {
            let date = new Date(newDocument.yearOfNextIU, e.target.value + period, 1);
            const finalDate = date.toISOString().split('T')[0];

            tempDoc.monthOfNextIU = e.target.value;
            tempDoc.permitExpirationDate = finalDate;

        } else if (e.target.name === "permitExpirationDate") {
            let date = new Date(e.target.value);
            tempDoc.permitExpirationDate = e.target.value;
            date.setMonth(date.getMonth() - period);
            tempDoc.monthOfNextIU = date.getMonth() + 1;
            tempDoc.yearOfNextIU = date.getFullYear();
            //tempDoc.checkingUI = date.getFullYear() - currentDate.getFullYear();
        } /*else if (e.target.name === "checkingUI") {
            console.log(tempDoc.yearOfNextIU);
            console.log(e.target.value);
            console.log(tempDoc.yearOfNextIU + e.target.value);
            let date = new Date(parseInt(tempDoc.yearOfNextIU) + e.target.value, tempDoc.monthOfNextIU + period, 1);
            tempDoc.monthOfNextIU = date.getMonth() + 1;
            tempDoc.yearOfNextIU = date.getFullYear();
            tempDoc.permitExpirationDate = date.toISOString().split('T')[0];
        }*/

        setNewDocument(tempDoc);
    }

    function handleChange(e) {
        setNewDocument({
            ...newDocument,
            [e.target.name]: e.target.value,
        });
    }

    function handleConstantMonitoringNeeded(e) {
        if (e.target.value === "true") {
            setConstantMonitoringNeeded(true);
            setNewDocument({
                ...newDocument,
                monitoringInterval: "Ständig övervakning krävs"
            });
        } else {
            setConstantMonitoringNeeded(false);
            setNewDocument({
                ...newDocument,
                monitoringInterval: ""
            });
        }
    }

    function handleTemplateChange(e) {
        setTemplateTypeId(e.target.value);
        let valueSplit = e.target.value.split(',');

        if (valueSplit.length === 3) {
            setIsCopy(true);
        } else {
            setIsCopy(false);
        }
    }

    function handleQuestionGroupChange(e) {
        setQuestionGroupId(e.target.value);
        axios.get("/FormQuestion/getall/" + e.target.value).then(
            res => {
                if (res.status === 200) {
                    setQuestionGroupId(e.target.value);
                    setQuestionList(res.data);

                    let checkedQuestionsChange = [];
                    for (var i = 0; i < res.data.length; i++) {
                        checkedQuestionsChange.push(true);
                    }

                    setCheckedQuestions(checkedQuestionsChange);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });
    }

    function handleChangeDocType(e) {
        let tempDoc = { ...newDocument }
        tempDoc.typeID = e.target.value;
        if (parseInt(e.target.value) > -1) {
            axios.get("/Templates/formTemplate/" + e.target.value + "/" + newDocument.objectID).then(
                res => {
                    if (res.status === 200) {
                        axios.get("/FormQuestionGroup/getall/").then(
                            res => {
                                if (res.status === 200) {
                                    setQuestionGroupList(res.data);
                                }
                            }
                        ).catch(err => {
                            console.log('err');
                            console.log(err);
                        });

                        if (e.target.value === 18) {
                            handleUsingTemplateRadio(true);
                        } else {
                            handleUsingTemplateRadio(false);
                        }

                        setTemplateList(res.data);
                        setTemplateTypeId(-1);

                        if (e.target.value === 18 || e.target.value === 19) {
                            handleUsingTemplateRadio(true);
                        } else {
                            handleUsingTemplateRadio(false);
                        }

                        if (e.target.value === 19) { //Archive
                            setDocName("Checklista: " + object.facility.name + " " + getProperCurrentDate());
                        } else {
                            let dTypeName = documentTypes.find(docType => docType.id === e.target.value).name;

                            if (object.objectNr) {
                                setDocName(dTypeName + "_" + object.objectNr);
                            } else {
                                setDocName(dTypeName);
                            }
                        }
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            });
        }

        if (object.type.mainType.id === 2 && e.target.value === 10) {
            tempDoc.permitExpirationDate = getProperCurrentDateAddMonths(2);
        } else {
            tempDoc.permitExpirationDate = getProperCurrentDate();
        }

        if (e.target.value === 12) {
            //Adjust IU dates
            let period = 0;

            if (object.controlRegulation === 1) {
                period = 0;
            } else if (object.controlRegulation === 2 || object.controlRegulation === 0) {
                period = 5;
            }

            let date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 + period, 1);
            tempDoc.permitExpirationDate = date.toISOString().split('T')[0];

            //newDocument.checkingUI
        }

        setNewDocument(tempDoc);
    }

    function handleChangeFLT(e) {
        if (e.target.name === 'executiveDate') {
            if (object.fltInterval > 0) {
                let newDoc = { ...newDocument };
                let newDate = new Date(e.target.value);
                newDate.setMonth(newDate.getMonth() + object.fltInterval);
                newDoc.dateOfNextFlt = newDate.toISOString().split('T')[0];
                newDoc.executiveDate = e.target.value;
                setNewDocument(newDoc);
            }
        } else {
            setNewDocument({
                ...newDocument,
                [e.target.name]: e.target.value,
            });
        }
    }

    function handleDatePickerChange(e) {
        //Has to be this way because the date picker element sends old values if other handleChange method is used
        const element = document.getElementsByName(e.target.name)[0];
        if (element) {
            setNewDocument({
                ...newDocument,
                [e.target.name]: element.value,
            });
        }
    }

    const handleNext = () => {
        setErrorMsg("");
        if (usingTemplateInstead) {
            let questionIDString = "";

            if (questionList.length > 0) {
                for (var i = 0; i < questionList.length; i++) {
                    if (checkedQuestions[i]) {
                        questionIDString += questionList[i].id + ","
                    }
                }
            }

            if (questionIDString.charAt(questionIDString.length - 1) === ",") {
                questionIDString = questionIDString.substring(0, questionIDString.length - 1);
            }

            let templatePayload = {
                objectID: newDocument.objectID,
                formTemplateID: templateTypeId.split(',')[1],
                questionIDs: questionIDString,
                newFormGroupName: questionGroupId === "0" ? "" : questionGroupList.find(qGroup => qGroup.id === questionGroupId).name,
                fltIsOkLift: newDocument.fltIsOkLift
            };

            axios.post('/Templates/CreateNewTemplate', templatePayload).then(res => {
                if (res.status === 200) {
                    let archiveStr = "";

                    if (isArchive) {
                        archiveStr = "&arkiv";
                    }

                    if (isCopy) {
                        window.location.href = '/#/app/mallar/mall?dokumentNamn=' + docName + '&objektID=' + object.id + "&mallID=" + res.data.id + "&dokumentTypID=" + newDocument.typeID + "&kopiaID=" + templateTypeId.split(',')[2] + "&skapa" + archiveStr;
                    } else {
                        window.location.href = '/#/app/mallar/mall?dokumentNamn=' + docName + '&objektID=' + object.id + "&mallID=" + res.data.id + "&dokumentTypID=" + newDocument.typeID + "&skapa" + archiveStr;
                    }
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
                setButtonClicked(false);
                setErrorMsg("Något gick fel, försök igen senare");
            })

        } else {
            let documentSubmission = { ...newDocument };
            documentSubmission.documentName = docName;

            if (newDocument.typeID === 9) {
                documentSubmission.dateOfNextJournal = dateOfNextJournal;
            } else if (newDocument.typeID === 20) {
                documentSubmission.executiveDate = documentSubmission.dateOfLastFlt;

                if (!documentSubmission.fltIsOkLift) {
                    documentSubmission.dateOfNextFlt = documentSubmission.dateOfLastFlt;
                }
            }

            axios.post('/Documents/create', documentSubmission).then(res => {
                if (res.status === 200) {
                    sendNotification();
                    if (isArchive) {
                        window.location.href = '/#/app/anläggningar/anläggning?objektID=' + newDocument.objectID;
                    } else {
                        window.location.href = '/#/app/objekt/dokument?objektID=' + newDocument.objectID;
                    }
                }
            }).catch(err => {
                console.log('err');
                console.log(err);
                setButtonClicked(false);
                setErrorMsg("Något gick fel, försök igen senare");
            })
        }
    };

    function getYearSpanFrom1970(yearSpan) {
        const currentYear = currentDate.getFullYear();

        let yearArray = [];

        for (var i = 1970; i <= currentYear + yearSpan; i++) {
            yearArray.push(i);
        }

        return yearArray;
    }

    function getYearSpan(yearSpan) {
        const currentYear = currentDate.getFullYear();

        let yearArray = [];

        for (var i = 0 - yearSpan; i <= yearSpan; i++) {
            yearArray.push(currentYear + i);
        }

        return yearArray;
    }

    function getYearSpanForward(yearSpan) {
        const currentYear = 2020;
        let yearArray = [];

        for (var i = 0; i <= yearSpan; i++) {
            yearArray.push((currentYear + i).toString());
        }

        return yearArray;
    }

    function getMonthSelectList() {
        let monthArray = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']

        return monthArray;
    }

    function sendNotification() {
        const componentProps = {
            type: "feedback",
            message: "Dokument tillagt!",
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function disableNextBtnLogic() {
        if ((newDocument.typeID === 7 && object.fltInterval < 1)) { //FLT, cannot create this while fltInterval isn't set
            return true;
        }

        if (buttonClicked) {
            return true;
        }

        if (docName.length === 0) {
            return true;
        }

        if (newDocument.typeID === 0) {
            return true;
        }

        if (newDocument.typeID === 6) { //Driftprov
            if (!newDocument.executiveDate || !newDocument.permitExpirationDate) {
                return true;
            }
        }

        if (newDocument.typeID === 9 && dateOfNextJournal.length === 0) { //LLJ
            return true;
        }

        return false;
    }

    function handleUsingTemplateRadio(bool) {
        setUsingTemplateInstead(bool);
    }

    function handleCheckClick(e, index) {
        let checkedQuestionsChange = [...checkedQuestions];
        checkedQuestionsChange[index] = e.target.checked;
        setCheckedQuestions(checkedQuestionsChange);
    }

    function handleDocNameChange(e) {
        if (e.target.value.length > 100) {
            return;
        }

        let newName = e.target.value.replace(/^.*[\\\/]/, '');

        var match = (new RegExp('[~#%\&?{}+\|]|\\.\\.|^\\.|\\.$')).test(newName);
        if (match) {
            console.log("Invalid");
        } else {
            setDocName(e.target.value);
        }
    }

    function handleChangeToggle(e) {
        setNewDocument({
            ...newDocument,
            [e.target.name]: e.target.checked,
        });
    }

    return (
        <Grid container spacing={3}>
            <Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} variant='h3'>
                {object && object.objectName}
            </Typography>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Skapa nytt dokument
                            </Typography>
                            {(newDocument.typeID === 9 || newDocument.typeID === 8 || newDocument.typeID === 7) &&
                                <div style={{ display: "flex", marginBottom: 30 }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Dokument eller mall</FormLabel>
                                        <RadioGroup aria-label="usingTemplate" name="usingTemplate" value={usingTemplateInstead}>
                                            <FormControlLabel value={false} control={<Radio className={classes.checkGreen} />} label="Använd dokument" onClick={() => handleUsingTemplateRadio(false)} />
                                            {canCreateTemplates && <FormControlLabel value={true} control={<Radio className={classes.checkGreen} />} label="Använd mall" onClick={() => handleUsingTemplateRadio(true)} />}
                                            {!canCreateTemplates && <FormControlLabel disabled value={true} control={<Radio className={classes.checkGreen} />} label="Använd mall - Behörighet saknas" />}
                                        </RadioGroup>
                                    </FormControl>
                                </div>}
                            <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl}>
                                <InputLabel>Dokumenttyp</InputLabel>
                                <Select
                                    name="typeID"
                                    value={newDocument.typeID}
                                    onChange={handleChangeDocType}
                                    label="Dokumenttyp"
                                    disabled={!canAddDocuments}
                                >
                                    <MenuItem key={0} value={0}>Dokumenttyp ej vald</MenuItem>
                                    {
                                        documentTypes.map(
                                            documentType => {
                                                return (
                                                    <MenuItem key={documentType.id} value={documentType.id}>{documentType.name}</MenuItem>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </FormControl>
                            {newDocument.typeID === 1 && < FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl}>
                                <InputLabel>Dokumenttyp</InputLabel>
                                <Select
                                    name="typeID"
                                    value={newDocument.typeID}
                                    onChange={handleChangeDocType}
                                    label="Dokumenttyp"
                                    disabled={!canAddDocuments}
                                >
                                    <MenuItem key={0} value={0}>Dokumenttyp ej vald</MenuItem>
                                    {
                                        documentTypes.map(
                                            documentType => {
                                                return (
                                                    <MenuItem key={documentType.id} value={documentType.id}>{documentType.name}</MenuItem>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </FormControl>}
                            <TextField
                                id="documentName"
                                label="Dokumentnamn"
                                onChange={e => { handleDocNameChange(e) }}
                                name="documentName"
                                value={docName}
                                variant="outlined"
                                style={{ marginBottom: 30 }}
                                helperText="Ange dokumentets namn, max 100 tecken"
                                disabled={!canAddDocuments}
                            />
                            {usingTemplateInstead && <>
                                <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl}>
                                    <InputLabel>Mall</InputLabel>
                                    <Select
                                        name="typeID"
                                        value={templateTypeId}
                                        onChange={handleTemplateChange}
                                        label="Mall"
                                        helperText="Välj mall"
                                    >
                                        {templateTypeId === -1 && <MenuItem is index={-1} key={-1} value={-1}>Malltyp ej vald</MenuItem>}
                                        {
                                            templateList.map(
                                                (template) => {
                                                    if (template.formID !== null) {
                                                        return (
                                                            <MenuItem key={template.id} value={template.id + "," + template.baseTemplate + "," + template.formID}>{template.name} - Kopiera senast ifyllda</MenuItem>
                                                        )
                                                    }

                                                    return (
                                                        <MenuItem key={template.id} value={template.id + "," + template.baseTemplate}>{template.name} - Blank mall</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl variant="outlined" style={{ marginBottom: 30 }} className={classes.formControl}>
                                    <InputLabel>Checklista</InputLabel>
                                    <Select
                                        name="typeID"
                                        value={questionGroupId}
                                        onChange={handleQuestionGroupChange}
                                        label="Checklista"
                                        helperText="Välj checklista"
                                    >
                                        <MenuItem is index={0} key={0} value={"0"}>Checklista ej vald</MenuItem>
                                        {
                                            questionGroupList.map(
                                                (questionGroup) => {
                                                    return (
                                                        <MenuItem key={questionGroup.id} value={questionGroup.id}>{questionGroup.name}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>

                                <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                    Välj frågor
                                </Typography>

                                <div className={classes.questionContainer}>
                                    {questionList.length === 0 && <Typography>Inga frågor att visa</Typography>}
                                    {questionList && questionList.map((question, index) =>
                                        <Grid container>
                                            <Grid item xs={1}>
                                                <GreenCheckbox
                                                    checked={checkedQuestions[index]}
                                                    name={"question" + question.id}
                                                    onClick={e => handleCheckClick(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography
                                                    variant={'h5'}
                                                    style={{ fontSize: "16px", lineHeight: '42px' }}
                                                >
                                                    {question.questionType === "header" ? <strong>{question.text}</strong> : question.text}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            {/*<Grid item xs={11}>*/}
                                            {/*    <Typography>*/}
                                            {/*        {question.description}*/}
                                            {/*    </Typography>*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    )}
                                </div>
                            </>
                            }

                            {/*
                                newDocument.typeID === id

                                "id": 5,
                                "name": "Översiktsbilder",

                                "id": 2,
                                "name": "Kontrollrapporter",

                                "id": 10,
                                "name": "Besiktning",

                                "id": 8,
                                "name": "Riskbedömning",

                                "id": 6,
                                "name": "Kontrollintyg DP",

                                "id": 12,
                                "name": "Kontrollintyg IU",

                                "id": 11,
                                "name": "Montagebesiktning",

                                "id": 16,
                                "name": "Serviceintyg",

                                "id": 4,
                                "name": "Flödesschema",

                                "id": 3,
                                "name": "Avvikelse",

                                "id": 1,
                                "name": "Övrigt"
                             */}

                            {
                                (newDocument.typeID === 10 && object.type.mainType.id === 2) &&
                                <>
                                    <TextField
                                        onChange={handleChangeLiftInspectionDates}
                                        name="previousDateOfLiftInspection"
                                        value={newDocument.previousDateOfLiftInspection}
                                        variant="outlined"
                                        style={{ marginBottom: 30 }}
                                        helperText="Ange besiktningsdatum"
                                        type="date"
                                    />
                                    <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                        Kontrollintervall kontrollorgan
                                    </Typography>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Intervall (År)
                                        </InputLabel>
                                        <Select
                                            value={newDocument.liftInspectionInterval}
                                            name="liftInspectionInterval"
                                            onChange={handleChangeLiftInspectionDates}
                                            label="Intervall (År)"
                                        >
                                            {
                                                objectData.liftInspections.map(
                                                    liftInspection => {
                                                        return (
                                                            <MenuItem key={liftInspection.name} value={liftInspection.value}>{liftInspection.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange intervall för besiktning
                                        </FormHelperText>
                                    </FormControl>

                                    {newDocument.liftInspectionInterval > 0 &&
                                        <>
                                            <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                                Nästa Besiktning
                                            </Typography>
                                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                                <FormControl
                                                    variant="outlined"
                                                    onChange={handleChangeLiftInspectionDates}
                                                    style={{ marginRight: 35 }}
                                                >
                                                    <InputLabel>
                                                        År
                                                    </InputLabel>
                                                    <Select
                                                        value={inspectionYear}
                                                        name="inspectionYear"
                                                        onChange={handleChangeLiftInspectionDates}
                                                        label="År"
                                                    >
                                                        {
                                                            getYearSpanFrom1970(30).map(
                                                                year => {
                                                                    return (
                                                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                    <FormHelperText>
                                                        Välj år och månad för nästa kontroll
                                                    </FormHelperText>
                                                </FormControl>

                                                <FormControl
                                                    variant="outlined"
                                                    onChange={handleChangeLiftInspectionDates}
                                                >
                                                    <InputLabel>
                                                        Månad
                                                    </InputLabel>
                                                    <Select
                                                        value={inspectionMonth}
                                                        name="inspectionMonth"
                                                        onChange={handleChangeLiftInspectionDates}
                                                        label="Månad"
                                                    >
                                                        {
                                                            getMonthSelectList().map(
                                                                (month, index) => {
                                                                    return (
                                                                        <MenuItem key={month} value={index + 1}>{month}</MenuItem>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    onChange={handleChangeLiftInspectionDates}
                                                    name="permitExpirationDate"
                                                    value={newDocument.permitExpirationDate}
                                                    variant="outlined"
                                                    style={{ marginLeft: 32 }}
                                                    helperText="Sista användningsdag"
                                                    type="date"
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {(newDocument.typeID === 9 && !usingTemplateInstead) &&
                                <FormControl
                                    variant="outlined"
                                >
                                    <InputLabel>
                                        Uppföljning år
                                    </InputLabel>
                                    <Select
                                        value={dateOfNextJournal}
                                        name="dateOfNextJournal"
                                        onChange={(e) => setDateOfNextJournal(e.target.value)}
                                        style={{ marginBottom: 35 }}
                                        displayEmpty
                                        label="Uppföljning år"
                                    >
                                        {
                                            getYearSpanForward(30).map(
                                                year => {
                                                    return (
                                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            }
                            {(newDocument.typeID === 20 && !usingTemplateInstead) && <>
                                <TextField
                                    onChange={handleChangeLiftInspectionDates}
                                    name="dateOfLastFlt"
                                    value={newDocument.dateOfLastFlt}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText="Senaste egenkontroll, utförsdatum"
                                    type="date"
                                />

                                <FormControl
                                    variant="outlined"
                                    //onChange={handleChange}
                                    style={{ marginBottom: 35 }}
                                >
                                    <InputLabel>
                                        Intervall egenkontroll
                                    </InputLabel>
                                    <Select
                                        value={newDocument.fltInterval}
                                        name="fltInterval"
                                        onChange={handleChangeLiftInspectionDates}
                                        label="Intervall egenkontroll"
                                    >
                                        {
                                            objectData.fltIntervalsLift.map(
                                                fltInterval => {
                                                    return (
                                                        <MenuItem key={fltInterval.name} value={fltInterval.value}>{fltInterval.name}</MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                {newDocument.fltInterval !== 0 && <TextField
                                    onChange={handleDatePickerChange}
                                    disabled
                                    name="dateOfNextFlt"
                                    value={newDocument.fltIsOkLift ? newDocument.dateOfNextFlt : newDocument.dateOfLastFlt}
                                    variant="outlined"
                                    style={{ marginBottom: 30 }}
                                    helperText="Datum för nästa egenkontroll"
                                    type="date"
                                />}
                                <div style={{ display: "flex", marginBottom: 35 }}>
                                    <Typography
                                        variant={'h5'}
                                        style={{ fontSize: "16px", lineHeight: '42px' }}
                                    >
                                        Egenkontroll godkänd
                                    </Typography>
                                    <GreenCheckbox
                                        checked={newDocument.fltIsOkLift}
                                        onClick={handleChangeToggle}
                                        name="fltIsOkLift"
                                    />
                                </div>
                            </>}

                            {!usingTemplateInstead && newDocument.typeID !== 20 &&
                                <TextField
                                    onChange={handleChange}
                                    name="executiveDate"
                                    value={newDocument.executiveDate}
                                    variant="outlined"
                                    style={newDocument.typeID != 10 ? { marginBottom: 30 } : { display: "none" }}
                                    helperText="Ange utförningsdatum"
                                    disabled={!canAddDocuments}
                                    type="date"
                                />
                            }
                            {
                                newDocument.typeID === 6 &&
                                <>
                                    {/*<FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35, marginRight: 35, width: "100%" }}
                                    >
                                        <InputLabel>
                                            Utförare AKO
                                        </InputLabel>
                                        <Select
                                            value={newDocument.executorAKODP}
                                            name="executorAKODP"
                                            onChange={handleChange}
                                            label="Utförare AKO"
                                        >
                                            {objectData.executorAKO.map(item => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>
                                            Välj vem som utför AKO
                                        </FormHelperText>
                                    </FormControl>*/}

                                    <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                        Nästa DP (FFM)
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                        <FormControl
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{ marginBottom: 35, marginRight: 35 }}
                                        >
                                            <InputLabel>
                                                År
                                            </InputLabel>
                                            <Select
                                                value={newDocument.yearOfNextDP}
                                                name="yearOfNextDP"
                                                onChange={handleChangeDPDates}
                                                label="År"
                                            >
                                                {
                                                    getYearSpanFrom1970(30).map(
                                                        year => {
                                                            return (
                                                                <MenuItem key={year} value={year}>{year}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Välj år och månad för nästa kontroll
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            variant="outlined"
                                            onChange={handleChangeDPDates}
                                            style={{ marginBottom: 35 }}
                                        >
                                            <InputLabel>
                                                Månad
                                            </InputLabel>
                                            <Select
                                                value={newDocument.monthOfNextDP}
                                                name="monthOfNextDP"
                                                onChange={handleChangeDPDates}
                                                label="Månad"
                                            >
                                                {
                                                    getMonthSelectList().map(
                                                        (month, index) => {
                                                            return (
                                                                <MenuItem key={month} value={index + 1}>{month}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            onChange={handleChangeDPDates}
                                            name="permitExpirationDate"
                                            value={newDocument.permitExpirationDate}
                                            variant="outlined"
                                            style={{ marginBottom: 35, marginLeft: 32 }}
                                            helperText="Sista användningsdag"
                                            type="date"
                                        />
                                    </div>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Intervall DP
                                        </InputLabel>
                                        <Select
                                            value={newDocument.checkingDP}
                                            name="checkingDP"
                                            onChange={handleChange}
                                            label="Kontroll DP"
                                        >
                                            {/*<MenuItem key={-10} value={-10}>DP-Kontroll ej valt</MenuItem>*/}
                                            {
                                                objectData.dpInspections.map(
                                                    dpInspection => {
                                                        return (
                                                            <MenuItem key={dpInspection.name} value={dpInspection.value}>{dpInspection.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange intervall av DP för objektet
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Intervall IU
                                        </InputLabel>
                                        <Select
                                            value={newDocument.checkingUI}
                                            name="checkingUI"
                                            onChange={handleChange}
                                            label="Intervall IU"
                                        >
                                            {/*<MenuItem key={-10} value={-10}>IU-Kontroll ej valt</MenuItem>*/}
                                            {
                                                objectData.iuInspections.map(
                                                    iuInspection => {
                                                        return (
                                                            <MenuItem key={iuInspection.name} value={iuInspection.value}>{iuInspection.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange intervall av IU för objektet
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }

                            {
                                newDocument.typeID === 12 &&
                                <>
                                    {/*<FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35, marginRight: 35, width: "100%" }}
                                    >
                                        <InputLabel>
                                            Utförare AKO
                                        </InputLabel>
                                        <Select
                                            value={newDocument.executorAKOIU}
                                            name="executorAKOIU"
                                            onChange={handleChange}
                                            label="Utförare AKO"
                                        >
                                            {objectData.executorAKO.map(item => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>
                                            Välj vem som utför AKO
                                        </FormHelperText>
                                    </FormControl>*/}

                                    <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                        Nästa IU (FFM)
                                    </Typography>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Intervall IU
                                        </InputLabel>
                                        <Select
                                            value={newDocument.checkingUI}
                                            name="checkingUI"
                                            onChange={handleChange}
                                            label="Intervall IU"
                                        >
                                            {/*<MenuItem key={-10} value={-10}>IU-Kontroll ej valt</MenuItem>*/}
                                            {
                                                objectData.iuInspections.map(
                                                    iuInspection => {
                                                        return (
                                                            <MenuItem key={iuInspection.name} value={iuInspection.value}>{iuInspection.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Ange intervall av IU för objektet
                                        </FormHelperText>
                                    </FormControl>
                                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                        <FormControl
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{ marginBottom: 35, marginRight: 35 }}
                                        >
                                            <InputLabel>
                                                År
                                            </InputLabel>
                                            <Select
                                                value={newDocument.yearOfNextIU}
                                                name="yearOfNextIU"
                                                onChange={handleChangeIUDates}
                                                label="År"
                                            >
                                                {
                                                    getYearSpanFrom1970(30).map(
                                                        year => {
                                                            return (
                                                                <MenuItem key={year} value={year}>{year}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>
                                                Välj år och månad för nästa kontroll
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            variant="outlined"
                                            onChange={handleChangeDPDates}
                                            style={{ marginBottom: 35 }}
                                        >
                                            <InputLabel>
                                                Månad
                                            </InputLabel>
                                            <Select
                                                value={newDocument.monthOfNextIU}
                                                name="monthOfNextIU"
                                                onChange={handleChangeIUDates}
                                                label="Månad"
                                            >
                                                {
                                                    getMonthSelectList().map(
                                                        (month, index) => {
                                                            return (
                                                                <MenuItem key={month} value={index + 1}>{month}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            onChange={handleChangeIUDates}
                                            name="permitExpirationDate"
                                            value={newDocument.permitExpirationDate}
                                            variant="outlined"
                                            style={{ marginBottom: 35, marginLeft: 32 }}
                                            helperText="Sista användningsdag"
                                            type="date"
                                        />
                                    </div>
                                </>
                            }

                            {
                                (!usingTemplateInstead && newDocument.typeID === 7) &&
                                <>
                                    {object.fltInterval === -1 &&
                                        <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                            Dokument för FLT-intervall saknas i detta objektet
                                        </Typography>
                                    }

                                    {object.fltInterval === 0 &&
                                        <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                            FLT-intervall för objektet är inte ett krav
                                        </Typography>
                                    }

                                    {object.fltInterval > 0 &&
                                        <Typography weight={'medium'} style={{ marginBottom: '16px' }}>
                                            FLT-intervall för objektet i månader: {object.fltInterval}
                                        </Typography>
                                    }

                                    <TextField
                                        onChange={handleDatePickerChange}
                                        disabled
                                        name="dateOfNextFlt"
                                        value={newDocument.dateOfNextFlt}
                                        variant="outlined"
                                        style={{ marginBottom: 30 }}
                                        helperText="Datum för nästa FLT"
                                        type="date"
                                    />
                                </>
                            }

                            {
                                (newDocument.typeID === 8 && !usingTemplateInstead) &&
                                <>
                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            FLT-intervall
                                        </InputLabel>
                                        <Select
                                            value={newDocument.fltInterval}
                                            name="fltInterval"
                                            onChange={handleChange}
                                            label="FLT-intervall"
                                        >
                                            {
                                                objectData.fltIntervals.filter(fltI => fltI.value > -1).map(
                                                    fltInterval => {
                                                        return (
                                                            <MenuItem key={fltInterval.name} value={fltInterval.value}>{fltInterval.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Välj vilket intervall Fortlöpande Tillsyn har
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Livslängdsjournal behövs
                                        </InputLabel>
                                        <Select
                                            value={newDocument.journalIsNeeded}
                                            name="journalIsNeeded"
                                            onChange={handleChange}
                                            label="Livslängdsjournal behövs"
                                        >
                                            {
                                                objectData.journalIsNeeded.filter(jour => jour.value > -1).map(
                                                    journalIsNeededChoice => {
                                                        return (
                                                            <MenuItem key={journalIsNeededChoice.name} value={journalIsNeededChoice.value}>{journalIsNeededChoice.name}</MenuItem>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                        <FormHelperText>
                                            Välj om livslängdsjournal behövs eller inte
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        variant="outlined"
                                        onChange={handleChange}
                                        style={{ marginBottom: 35 }}
                                    >
                                        <InputLabel>
                                            Krävs ständig övervakning?
                                        </InputLabel>
                                        <Select
                                            value={constantMonitoringNeeded}
                                            name="constantMonitoringNeeded"
                                            onChange={handleConstantMonitoringNeeded}
                                            label="Krävs ständig övervakning?"
                                        >
                                            <MenuItem key={0} value={"true"}>Ja</MenuItem>
                                            <MenuItem key={1} value={"false"}>Nej</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            Välj om ständig övervakning krävs eller om det endast behöver intervallövervakning
                                        </FormHelperText>
                                    </FormControl>
                                    {!constantMonitoringNeeded && <TextField
                                        id="monitoringInterval"
                                        label="Övervakningsintervall"
                                        onChange={handleChange}
                                        name="monitoringInterval"
                                        value={newDocument.monitoringInterval}
                                        variant="outlined"
                                        style={{ marginBottom: 30 }}
                                        helperText="Ange övervakningsintervallet"
                                    />}
                                </>
                            }
                            {!usingTemplateInstead && (newDocument.documentFileID ?
                                <>
                                    <Typography style={{ marginBottom: '6px' }} weight={'medium'}>
                                        Uppladdat dokument
                                    </Typography>

                                    <div className={classes.galleryWrap}>
                                        <div className={classes.docMargin} style={{ display: 'table' }}>
                                            <span onClick={removeClick} className={classes.deleteImageTrashcan}>{<Delete />}</span>
                                        </div>

                                        <div className={classes.docMargin} style={{ display: 'table' }}>
                                            <span className={classes.docImg}>{<Description />}</span>
                                        </div>

                                        <div style={{ display: 'table' }}>
                                            {docFileName && (<span className={classes.verticalAlignCell}>{docFileName}</span>)}
                                        </div>
                                    </div>
                                </>
                                :
                                <>{canAddDocuments &&
                                    <>
                                        <label
                                            className={classes.uploadLabel}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Description className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                            {'Ladda upp ett dokument'}
                                            <input style={{ display: 'none' }} accept="*" type="file" ref={fileInput} onChange={handleFile} />
                                        </label>
                                        <Typography
                                            size={'sm'}
                                            style={{ marginBottom: 30 }}
                                        >
                                            Accepterade filformat: .PDF, .DOC, .TXT
                                        </Typography>
                                    </>}
                                </>
                            )}

                            {(newDocument.typeID === 7 && object.fltInterval < 1) &&
                                <Typography weight={'medium'} style={{ marginBottom: '16px', color: "red" }}>
                                    Det behövs ett Riskbedömningsdokument för objektet innan du kan skapa ett dokument för Fortlöpande Tillsyn
                                </Typography>
                            }

                            {!canAddDocuments &&
                                <Typography weight={'medium'} style={{ marginBottom: '16px', color: "red" }}>
                                    Du saknar behörighet för att lägga till dokument
                                </Typography>
                            }

                            <div>
                                {errorMsg.length > 0 &&
                                    <Typography weight={'medium'} style={{ marginBottom: '16px', color: "red", textAlign: "right" }}>
                                        {errorMsg}
                                    </Typography>
                                }
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    {usingTemplateInstead ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={templateTypeId === -1 || (newDocument.typeID === 7 && object.fltInterval < 1) || docName.length === 0 || buttonClicked}
                                        >
                                            {isCopy ? "Börja fylla i kopia av mall" : "Börja fylla i mall"}
                                        </Button>)
                                        :
                                        (<Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { setButtonClicked(true); handleNext(); }}
                                            disabled={disableNextBtnLogic()}
                                        >
                                            Skapa dokument
                                        </Button>)
                                    }
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    )
}

export default AddDocument
